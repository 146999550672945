import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'account-setup',
    data() {
        return {
            menuSteps: [
                {
                    name: 'identification',
                    label: 'ID Check',
                },
                {
                    name: 'photo',
                    label: 'Profile Image'
                },
                {
                    name: 'contact',
                    label: 'Phone Number'
                },
                {
                    name: 'terms',
                    label: 'Terms & conditions'
                }
            ],
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user', 'accountSetupCache']),
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        currentRouteName() {
            const route = this.$route;
            return route.name;
        },
    },
    methods: {
        ...mapActions(USERS_STORE, ['updateUserAccountSetupCache']),
        getMenuSteps() {
            return this.menuSteps;
        },
        onNavigateStep(menu) {
            this.updateUserAccountSetupCache();
            this.$router.push({ name: `setup/${menu.name}` });
        }
    }
});
